<template>
  <v-menu
    bottom
    offset-y
    left
    v-model="menu"
    close-on-click
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-badge color="red" overlap v-model="hasUnread" bottom>
        <span slot="badge">{{unread}}</span>
        <v-btn
          color="color3"
          small fab
          v-on="on"
        >
          <v-icon color="color3Text">fas fa-bell</v-icon>
        </v-btn>
      </v-badge>
    </template>
    <!-- USER MENU -->
    <v-list dense>
      <template v-for="(item, i) in notifications">
        <v-list-item :key="item.id">
          <v-list-item-action class="mr-3 clickable" @click.stop="clicked(item)">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content @click="clicked(item)" class="clickable">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.body }}</v-list-item-subtitle>
            <v-list-item-subtitle :class="{'color3--text': !item.read}">{{ when(item.dtCreated) }} ago</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="mr-3" @click="markAsRead(item)">
            <v-icon color="color3" x-small v-if="!item.read">fas fa-circle</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="i < (notifications.length - 1)" :key="`${item.id}d`"></v-divider>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  data () {
    return {
      menu: false,
      loading: false,
      page: 0,
      to: null
    }
  },
  computed: {
    ...mapGetters(['user', 'unread', 'notifications']),
    hasUnread () {
      return this.unread > 0
    }
  },
  methods: {
    clicked (n) {
      this.markAsRead(n)
      this.$router.push(n.actionURL)
      this.menu = false
    },
    when (dtCreated) {
      var x = moment()
      var y = moment(dtCreated)
      var duration = moment.duration(x.diff(y))
      return duration.humanize()
    },
    load () {
      this.loading = true
      this.$VBL.user.notification.get(this.page)
        .then(r => {
          this.user.notifications = r.data
        })
        .catch(e => console.log(e.response))
        .finally(f => {
          this.loading = false
        })
    },
    markAsRead (item) {
      if (item.read) return
      this.$VBL.user.notification.markAs('read', { ids: [item.id] })
        .then(r => {
          item.read = true
        })
        .catch(e => console.log(e.response))
        .finally(f => {
          this.loading = false
        })
    },
    markShown () {
      this.$VBL.user.notification.markAs('shown', { ids: [] })
        .catch(e => console.log(e.response))
        .finally(f => {
          clearTimeout(this.to)
        })
    }
  },
  mounted () {
    this.load()
  },
  watch: {
    menu: function (val) {
      clearTimeout(this.to)
      if (val) {
        this.to = setTimeout(this.markShown, 1313)
      }
    }
  }
}
</script>
